




const Header = () => {
  return <header>
    <div className={`container`}>

      <img src='pp-reversed-logo.svg' alt='logo' />
      <h2>Feed the penguin to receive a prize!</h2>

      </div>
  </header>
}

export default Header